<template>
	<v-card>
		<v-card-text>
			<p>
				Use this checkbox to remove yourself as moderator.
				<v-checkbox
					label="Stop Moderating"
					v-model="stop"
				></v-checkbox>
			</p>
		</v-card-text>
		<v-card-actions>
			<v-btn @click="$emit('close')" text color="secondary">Cancel</v-btn>
			<v-spacer></v-spacer>
			<v-btn icon color="primary" @click="update"
				><v-icon>mdi-check-circle</v-icon></v-btn
			>
		</v-card-actions>
	</v-card>
</template>

<script>
export default {
	name: "ModeratorControls",
	data: () => {
		return {
			dialog: false,
			stop: false,
			selected: null,
		};
	},

	computed: {
		localIdentity() {
			return this.$store.state.auth.data.id;
		},
		room() {
			return this.$store.state.videoRoom.data;
		},
		active() {
			return this.room.moderator == this.localIdentity;
		},
	},
	methods: {
		update() {
			if( this.stop ){
				this.$store.dispatch("videoRoom/patch", {
					moderator: null,
				});
			}
			this.$emit("close");
		},
	},
	created() {
		this.selected = this.room.moderator;
	},
};
</script>
