<template>
<v-sheet v-if="mainContent" class="main-track">
		<div v-if="track">
			<video-track
				size="main"
				:track="track"
			></video-track>
		</div>
		<participant-avatar
			v-else
			v-model="identity"
		></participant-avatar>
		<audio-icon v-if="!isLocal" v-model="identity"></audio-icon>
		<participant-identity v-if="!isLocal" v-model="identity"></participant-identity>
</v-sheet>
</template>

<style lang="less">
	.main-track {
		position: relative;
		width: 100%;
		height: auto;
	}
</style>
<script>
import ParticipantAvatar from "@c/video/ParticipantAvatar";
import ParticipantIdentity from "@c/video/ParticipantIdentity";
import VideoTrack from "@c/video/VideoTrack";
import AudioIcon from "@c/video/AudioIcon";
export default {
    name: "MainTrack", 
    computed: {
        mainContent(){
            return this.$store.state.videoRoom.main_track;
        },
        track() {
            return this.mainContent.track;
        },
        identity(){
           return this.mainContent.identity; 
        },
		localIdentity() {
			return this.$store.state.auth.data.id;
		},
		isLocal(){
			return this.localIdentity == this.identity;
		}
    },
	components: {
		ParticipantAvatar,
		ParticipantIdentity,
		AudioIcon,
		VideoTrack,
	},
}
</script>