<template>
	<v-menu
		v-model="open"
		bottom
		origin="center center"
		:close-on-click="false"
		:close-on-content-click="false"
		transition="scale-transition"
	>
		<template v-slot:activator="{ on }">
			<v-btn color="primary" dark fab v-on="on" absolute style="top: 40px; left: 20px">
				<v-icon dark>mdi-note-plus</v-icon>
			</v-btn>
		</template>
		<v-card flat width="350">
			<v-card-title>
				<v-spacer></v-spacer>
				<v-btn @click="open = false" icon small
					><v-icon>mdi-close</v-icon></v-btn
				>
			</v-card-title>
			<v-card-text>
                This is an area for you to share text with the participant. This information will appear on their screen when you press save. 
				<v-textarea v-model="videoRoom.notes" rows="8" placeholder="Start typing"></v-textarea>
			</v-card-text>
			<v-card-actions>
                <span v-if="saved" color="success"> <v-icon class="mr-2" color="primary">mdi-check-circle </v-icon>Saved</span>
                <v-progress-circular class="ml-2" indeterminate size="20" width="3" color="primary" v-if="saving"></v-progress-circular>
				<v-spacer></v-spacer>
				<v-btn @click="save" text color="primary">Save</v-btn>
			</v-card-actions>
		</v-card>
	</v-menu>
</template>

<script>
export default {
	name: "StickyNotes",
	data: () => {
		return {
			open: false,
            saving: false,
            saved: false,
		};
	},
    computed: {
        videoRoom(){
            return this.$store.state.videoRoom.data;
        },
    },
    methods: {
        save(){
            this.saving = true;
            this.$store.dispatch("videoRoom/patch", {notes: this.videoRoom.notes }).then( () => {
                this.saving = false;
                this.saved = true;
            })
        }
    },
    watch: {
        saved(v){
            const self = this;
            if( v ){
                setTimeout( () => {
                    self.saved = false;
                }, 3000)
            }
        }
    }
};
</script>
