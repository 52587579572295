<template>
	<v-chip
		small
		color="secondary"
		style="position: absolute; bottom: 15px; left: 10px"
		>{{ text }}</v-chip
	>
</template>

<script>
export default {
	name: "RemoteParticipantIdentity",
	props: {
		value: { type: String },
	},
	computed: {
		room() {
			return this.$store.state.videoRoom.data;
		},
		localIdentity() {
			return this.$store.state.auth.data.id;
		},
		text() {
			let label = this.room.identities[this.value];
			if (this.value == this.room.identities) {
				label += " (You)";
			}
			return label || "Unknown participant";
		},
	},
};
</script>
