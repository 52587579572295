<template>
	<span>
		<listen-for-prompt @input="startScreenSharing"></listen-for-prompt>
		<v-btn v-if="!mobile"
			:color="screenShareTrack ? 'red' : 'primary'"
			dark
			outlined
			class="mr-2 wrapped right"
			small
			@click="toggle"
		>
			<span v-if="screenShareTrack">
				Stop <br/> Sharing
			</span>
			<span v-else>
				Share <br />
				Screen
			</span>
		</v-btn>
	</span>
</template>

<style lang="less">
.v-btn.wrapped {
	height: auto !important;
	.v-btn__content {
		padding-top: 2px !important;
		padding-bottom: 2px !important;
	}
	&.right {
		position: absolute;
		right: 2px;
		bottom: 4px;
	}
}
</style>

<script>
import videoUtilities from "@/assets/videoUtilities.js";
import ListenForPrompt from "@c/video/ListenForPrompt";
export default {
	name: "ShareScreenButton",
	data: () => ({
	}),
	inject: ["isMobile"],
	props: {
		room: { type: Object },
	},
	components: {
		ListenForPrompt
	},
	computed: {
		mobile() {
			return this.$vuetify.breakpoint.xsAndDown
		},
		localParticipant() {
			return this.room.localParticipant;
		},
		screenShareTrack(){
			return this.$store.state.videoRoom.local_screen_track
		}
	},
	methods: {
		startScreenSharing() {
			const self = this;
			videoUtilities
				.getScreenTrack(self.localParticipant)
				.then((track) => {
					self.$store.commit("videoRoom/START_SCREENSHARE", track)
					self.room.localParticipant.publishTrack(track);
				})
				.catch(() => {
					this.stop();
				});
		},
		toggle() {
			if (this.screenShareTrack) {
				this.stop();
			} else {
				this.start();
			}
		},
		start() {
			const self = this;
			self.room.localParticipant.on("trackStopped", (track) => {
				if (track == this.screenShareTrack ) {
					self.stop();
				}
			});
			self.startScreenSharing();
		},
		stop() {
			this.$root.$emit("stopScreenshare")
		},
	}
};
</script>
