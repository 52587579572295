<template>
	<div>
		<audio-track
			v-for="(track, i) in audioTracks"
			:key="i"
			:track="track"
		></audio-track>
	</div>
</template>

<script>
import AudioTrack from "@c/video/AudioTrack.vue";

export default {
	name: "AudioTracks",
	props: {},
	computed: {
		audioTracks() {
			return this.$store.state.videoRoom.audio_tracks;
		},
	},
	components: { AudioTrack },
};
</script>
