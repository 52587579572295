<template>
	<div class="button-drawer">
		<v-btn
			v-if="isModerator"
			outlined
			icon
			small
			:class="{ 'mb-2': isMobile }"
			class="mr-2"
			@click="audio = !audio"
			:color="audio ? 'green' : ''"
		>
			<v-icon small>{{
				audio ? "mdi-microphone" : "mdi-microphone-off"
			}}</v-icon></v-btn
		>
		<v-btn
			v-if="isModerator"
			outlined
			icon
			small
			:class="{ 'mb-2': isMobile }"
			class="mr-2"
			@click="video = !video"
			:color="video ? 'green' : ''"
		>
			<v-icon small>{{
				video ? "mdi-video" : "mdi-video-off"
			}}</v-icon></v-btn
		>
		<v-btn
			outlined
			icon
			small
			:class="{ 'mb-2': isMobile }"
			class="mr-2"
			@click="stop"
			color="red"
		>
			<v-icon small>mdi-phone-hangup</v-icon></v-btn
		>
		<screen-share-button v-if="isModerator" :room="room"></screen-share-button>
	</div>
</template>
<style lang="less">
.button-drawer {
	padding: 3px 8px;
	margin-bottom: 10px;
	position: absolute;
	bottom: 0;
	width: 100%;
	.v-btn {
		background: white;
	}
}
</style>
<script>
import { createLocalVideoTrack, createLocalAudioTrack } from "twilio-video";
// import videoUtilities from "@/assets/videoUtilities.js";
import ScreenShareButton from "@c/video/ScreenShareButton.vue";
export default {
	name: "LocalControls",
	inject: ["isMobile"],
	props: {
		room: { type: Object },
	},
	components: { ScreenShareButton },
	data: () => {
		return {
			audio: false,
			video: false,
		};
	},
	computed: {
		videoRoom() {
			return this.$store.state.videoRoom.data;
		},
		localIdentity() {
			return this.$store.state.auth.data.id;
		},
		isModerator() {
			return this.videoRoom.moderator == this.localIdentity;
		},
		localParticipant() {
			return this.room.localParticipant;
		},
		localVideoTrack() {
			return this.$store.state.videoRoom.local_video_track;
		},
		localAudioTrack() {
			return this.$store.state.videoRoom.local_audio_track;
		},
	},
	methods: {
		async getLocalVideo() {
			return createLocalVideoTrack().then((track) => {
				return track;
			});
		},
		async getLocalAudio() {
			return createLocalAudioTrack().then((track) => {
				return track;
			});
		},
		startAudio() {
			const self = this;
			self.getLocalAudio().then((track) => {
				if (track) {
					self.audio = true;
					self.room.localParticipant.publishTrack(track);
				}
			});
		},
		stopAudio() {
			this.$root.$emit( "stopLocalAudio" );
			this.audio = false;
		},
		startVideo() {
			const self = this;
			self.getLocalVideo().then((track) => {
				if (track) {
					self.video = true;
					self.$root.$emit("startLocalVideo", track )
					self.room.localParticipant.publishTrack(track);
				}
			});
		},
		stopVideo() {
			const self = this;
			this.$root.$emit( "stopLocalVideo" );
			self.video = false;
		},
		stop() {
			if (this.room) {
				this.audio = false;
				this.video = false;
				this.$emit("leaveRoom");
			}
		},
	},
	watch: {
		audio(v){
			if( v ){
				this.startAudio()
			}
			else{
				this.stopAudio();
			}
		},
		video(v){
			if( v ){
				this.startVideo()
			}
			else{
				this.stopVideo();
			}
		},
		localParticipant: {
			immediate: true,
			handler(value) {
				if (value.audioTracks.size) {
					this.audio = true;
				}
				if (value.videoTracks.size) {
					this.video = true;
				}
			},
		},
	},
	mounted() {
		this.$root.$on("turnOnAv", () => {
			this.video = true;
			this.audio = true;
		})
	}
};
</script>
