<template>
    <div></div>
</template>
<script>
export default {
	name: "ListenToPrompt",

	computed: {
		localIdentity() {
			return this.$store.state.auth.data.id;
		},
		room() {
			return this.$store.state.videoRoom.data;
		},
		prompt() {
			return this.room.prompt[this.localIdentity];
		},
	},
	methods: {
		reset(){
			this.$store.dispatch("videoRoom/patch", {prompt: {[this.localIdentity]: false }})
		}
	},
    watch: {
        prompt(v) {
            if( v ){
                this.$emit("input");
				this.reset();
			}
        }
    },
	created() {
		this.reset()
	}
	
	};
</script>
