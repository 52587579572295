<template>
	<v-list dense>
		<v-list-item >
			<v-list-item-content>
				<v-list-item-title>
					In this Meeting
				</v-list-item-title>
			</v-list-item-content>
		</v-list-item>

		<participant-thumbnail
			v-for="participant in participants"
			:key="participant"
			:identity="participant"
		></participant-thumbnail>
		<v-list-item v-if="!haveParticipants">
			<v-list-item-subtitle>
				You're the only person here
			</v-list-item-subtitle>
		</v-list-item>
	</v-list>
</template>

<script>
import ParticipantThumbnail from "@c/video/ParticipantThumbnail";
export default {
	name: "ParticipantList",
	components: {
		ParticipantThumbnail,
	},
	computed: {
		participants() {
			return this.$store.state.videoRoom.remote_participants;
		},
		haveParticipants() {
			return this.participants.length > 0;
		},
	},
};
</script>
