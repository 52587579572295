import firebase from "firebase";
import { LocalVideoTrack } from "twilio-video";

export default {
	getToken: async function(roomName, identity) {
		const functions = firebase.app().functions("europe-west1");
		const getToken = functions.httpsCallable("getVideoToken");
		const credentials = {
			roomName,
			identity,
		};
		const result = await getToken(credentials);
		return result.data.token;
	},
	getScreenTrack: async function(user) {
		let options = {};
		if( user ){
			options.name = `${user}-screen`;
		}
		return navigator.mediaDevices.getDisplayMedia().then((stream) => {
			const screenTrack = stream.getVideoTracks()[0];
			return new LocalVideoTrack(screenTrack, options );
		});
	},
	attachTrack(track, container) {
		container.appendChild(track.attach());
	},
	detachTracks: function(tracks) {
		tracks.forEach(function(track) {
			track.detach().forEach(function(detachedElement) {
				detachedElement.remove();
			});
		});
	},
	trackPublished(publication) {		
		publication.on("subscribed", (track) => {
			this.attachTrack(track, document.body );
		});
		publication.on("unsubscribed", (track) => {
			this.detachTracks([track])
			console.log(
				`LocalParticipant unsubscribed from a RemoteTrack: ${track}`
			);
		});
	},
	handleRemoteParticipant(participant ) {
		participant.audioTracks.forEach((publication) => {
			this.trackPublished(publication);
		});
		participant.on("trackPublished", (publication) => {
			if( publication.kind == "audio" ){
				this.trackPublished(publication);
			}
		});
		participant.on("trackAdded", (track) => {
			if( track.kind == "audio" ){
				this.attachTrack(track.track, document.body);
			}
		});
	},
	handleParticipantDisconnected(participant) {
		let tracks = Array.from(participant.tracks.values());
		this.detachTracks(tracks);
	},

	getVideoTrack(participant) {
		let tracks = Array.from(participant.videoTracks.values());
		return tracks[0];
	},
	getVideoTracks(participant) {
		let tracks = Array.from(participant.videoTracks.values());
		return tracks;
	},
};
