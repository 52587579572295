<template>
    <div></div>
</template>

<script>

    export default {
        name: "AudioTracks", 
        props: {
            track: {type: Object}
        },
        computed: {
            content() {
                return this.track.attach();
            },
        },
        methods: {
            attach() {
                this.$el.append(this.content);
            },
        }, 
        mounted(){
            this.attach();
        }
    }
</script>