<template>
	<v-card flat>
		<v-card-text>
			<v-text-field
				v-model="value"
				label="Your name"
				persistent-hint
				hint="As it will appear to other members"
			></v-text-field>
		</v-card-text>
        <v-card-actions>
			<v-btn @click="$emit('close')" text color="secondary">Cancel</v-btn>
            <v-spacer></v-spacer>
			<v-btn icon color="primary" @click="update"><v-icon>mdi-check-circle</v-icon></v-btn>
        </v-card-actions>
	</v-card>
</template>

<script>
export default {
	name: "Identity",
	data: () => {
		return {
			value: null
		}
	},
	computed: {
		user() {
			return this.$store.state.auth.data;
		},
		videoRoom() {
			return this.$store.state.videoRoom.data;
		},
		identity() {
			return this.videoRoom.identities[this.user.id];
		},
	},
	watch: {
		identity: {
			immediate: true, 
			handler(){
				this.value = this.identity;
			}
		}
	},
	methods: {
		update() {
			this.$store.dispatch("videoRoom/patch", {
				identities: { [this.user.id]: this.value },
			}).then( () => {
				this.$emit("close");
			})
		},
	},
};
</script>
