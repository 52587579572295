<template>
	<v-alert type="info" text class="pa-3 mb-0" dense>
		<small v-if="!isModerator">
			Your microphone and camera are disabled because you are not the
			moderator.
		</small>
		<small v-else>
			You are moderating
		</small>
	</v-alert>
</template>

<script>
export default {
	name: "ModeratorInstructions",
	computed: {
		localIdentity() {
			return this.$store.state.auth.data.id;
		},
		room() {
			return this.$store.state.videoRoom.data;
		},
		isModerator() {
			return this.room.moderator == this.localIdentity;
		},
	},
};
</script>
