<template>
	<v-sheet flat>
		<template v-if="logged_in">
			<room v-if="start" :roomName="id"></room>
			<template v-else>
				<v-card v-if="canAccess" color="lightest" class="rounded-xl" flat>
					<v-card-text>
						You are entering a video conference. This is being recorded. Your microphone and camera will remain disabled unless you are the moderator. 
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn color="primary" @click="start = true">
							Enter 
						</v-btn>
					</v-card-actions>
				</v-card>
				<v-card v-else color="lightest" class="rounded-xl" flat>
					<v-card-text>
						Please wait to be admitted to the video conference
					</v-card-text>
				</v-card>
			</template>
		</template>
		<template v-else>
			<v-card color="lightest" class="rounded-xl" flat
				><v-card-text
					>Please
					<a @click="$root.$emit('openLogin')">log in</a></v-card-text
				></v-card
			>
		</template>
	</v-sheet>
</template>

<script>
import Room from "@c/video/Room.vue";
export default {
	name: "VideoContainer",
	props: {
		id: { type: String },
	},
	data: () => {
		return {
			start: null,
		};
	},
	components: { Room },
	computed: {
		auth() {
			return this.$store.state.auth.data;
		},
		logged_in() {
			return this.auth.id || false;
		},
		videoRoom() {
			return this.$store.state.videoRoom.data;
		},
		canAccess() {
			return this.$store.getters["videoRoom/canAccess"];
		},
	},
	watch: {
		auth: {
			immediate: true,
			handler(value) {
				if (value) {
					this.$store.dispatch("videoRoom/start", this.id);
				}
			},
		},
		canAccess(value) {
			if (value) {
				this.$store.dispatch("videoRoom/enterConference");
			}
		},
	},
	destroyed() {
		this.$store.dispatch("videoRoom/leaveConference");
	},
};
</script>
