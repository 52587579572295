<template>
	<v-card>
		<v-card-text>
			<p>
				Use the buttons below to prompt participants to share their screens.
			</p>
			<v-list>
				<v-list-item dense v-for="person in people" :key="person.value" @click="submit(person.value)">
					<v-list-item-title>
						{{ person.label }}
					</v-list-item-title>
					<v-list-item-action> </v-list-item-action>
				</v-list-item>
			</v-list>
		</v-card-text>
		<v-card-actions>
			<span v-if="saved" color="success">
				<v-icon class="mr-2" color="primary">mdi-check-circle </v-icon
				>Saved</span
			>
			<v-progress-circular
				class="ml-2"
				indeterminate
				size="20"
				width="3"
				color="primary"
				v-if="saving"
			></v-progress-circular>

			<v-spacer></v-spacer>
			<v-btn icon @click="$emit('close')">
				<v-icon>mdi-check-circle</v-icon>
			</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
export default {
	name: "PromptParticipants",
	data: () => {
		return {
			saving: false,
			saved: false,
		};
	},

	computed: {
		localIdentity() {
			return this.$store.state.auth.data.id;
		},
		room() {
			return this.$store.state.videoRoom.data;
		},
		people() {
			const self = this;
			return self.room.members
				.map((a) => {
					let label = self.room.identities[a];
					if (self.localIdentity == a) {
						label += " (You)";
					}
					return { value: a, label: label };
				})
				.filter((a) => a.value !== self.localIdentity);
		},
	},
	methods: {
		submit(id) {
			this.saving = true;
			this.$store
				.dispatch("videoRoom/patch", { prompt: { [id]: true } })
				.then(() => {
					this.saving = false;
					this.saved = true;
				});
		},
	},
	watch: {
		saved(v) {
			const self = this;
			if (v) {
				setTimeout(() => {
					self.saved = false;
				}, 3000);
			}
		},
	},
};
</script>
