<template>
    <div class="centered" @click="$emit('select')">
        <v-avatar size="56" color="primary">
            <span v-if="initial">{{initial}}</span>
            <v-icon v-else>mdi-account</v-icon>
        </v-avatar>
    </div>
</template>


<style lang="less">
    .centered {
        cursor: pointer;
        text-align: center;
        display: block;
        padding-top: 40px;
        height: 100%;
        position: absolute;
        width: 100%
    }
</style>

<script>
export default {
    name: "ParticipantAvatar", 
    props: {
        value: {type: String }
    }, 
    computed: {
        initial(){
            if( this.videoRoom.identities[this.value] ){
                return this.videoRoom.identities[this.value].substr(0, 1);
            }
            else{
                return null
            }
        }, 
        videoRoom(){
            return this.$store.state.videoRoom.data;
        }, 
    }
}
</script>