<template>
	<v-avatar color="white" size="22"
		style="position: absolute; right: 5px; top: 5px; z-index: 10"
	>
		<v-icon small> mdi-microphone{{ haveAudio ? "" : "-off" }} </v-icon>
	</v-avatar>
</template>

<script>
export default {
	name: "AudioIcon",
	props: {
		value: { type: String },
	},
	computed: {
		audioTracks() {
			return this.$store.getters["videoRoom/participantAudio"](this.value);
		},
		haveAudio() {
			return this.audioTracks.length > 0;
		},
	},
};
</script>
